.timer-wrapper {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin: 20px;
  z-index: 2;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
