.create h1 {
  color: white;
}
table {
  width: 100%;
}

.card {
  min-width: 100%;
  width: min-content;
}

.col-md-6 {
  max-width: 100%;
}

.c-td-name {
  width: 80%;
  min-width: 76px;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spectator-row {
  border-top: 5px double #eee;
}
